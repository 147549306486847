import React from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'

import string_to_slug from '../../utils/slug'
import Arrow from '../../images/arrow.inline.svg'
import { isType } from '../../utils/typecheck'

export default function JobLink({ jobSlug = '' }) {
    // Must be styled in parent element.

    return (
        <StaticQuery
            query={graphql`
                query {
                    allAirtable(
                        filter: { table: { eq: "KarriereSingle" } }
                        sort: { order: ASC, fields: rowIndex }
                    ) {
                        group(field: data___Slug) {
                            nodes {
                                data {
                                    Type
                                    Content
                                    Slug
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) =>
                data.allAirtable.group.map((job, index) => {
                    if (
                        jobSlug &&
                        job.nodes[0].data.Slug[0] === string_to_slug(jobSlug)
                    ) {
                        let jobItem: any = {}
                        jobItem.link = `/karriere/${job.nodes[0].data.Slug}`
                        job.nodes.map((textItem) => {
                            switch (true) {
                                case isType(textItem.data.Type, 'H1'):
                                    jobItem.title = textItem.data.Content
                            }
                        })

                        return (
                            <Link to={jobItem.link} className="job" key={index}>
                                <div className="title">{jobItem.title}</div>
                                <div className="link">
                                    <span>Mehr erfahren</span>
                                    <button className="button arrow right color-primary">
                                        <Arrow />
                                    </button>
                                </div>
                            </Link>
                        )
                    }
                })
            }
        />
    )
}
