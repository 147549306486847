import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Arrow from '../../images/arrow.inline.svg'

export default function WideArrowLink({ onWhiteBackground = false, text }) {
    if (!onWhiteBackground) {
        return (
            <LinkNormal className="link-container">
                <Link
                    to={
                        text.split(' | ')[1]
                            ? text.split(' | ')[1].replace('\n', '')
                            : '#'
                    }
                >
                    <span className="button arrow right color-white">
                        <Arrow />
                    </span>
                    <span>{text.split(' | ')[0]}</span>
                </Link>
            </LinkNormal>
        )
    }
    if (onWhiteBackground) {
        return (
            <LinkOnWhite className="link-container">
                <Link
                    to={
                        text.split(' | ')[1]
                            ? text.split(' | ')[1].replace('\n', '')
                            : '#'
                    }
                >
                    <span className="button arrow right color-primary">
                        <Arrow />
                    </span>
                    <span>{text.split(' | ')[0]}</span>
                </Link>
            </LinkOnWhite>
        )
    }
    return null
}

const LinkNormal = styled.div`
    grid-column: 3 / -2 !important;
    margin-top: 3rem;

    @media (min-width: ${(props) => props.theme.md}) {
        grid-column: 2 / -2 !important;
        margin-top: 5rem;
    }

    @media (min-width: ${(props) => props.theme.lg}) {
        grid-column: 3 / -3 !important;
    }

    a {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 0.888rem;
            font-family: 'SawtonBauhaus';
            font-weight: 500;
        }
    }
`

const LinkOnWhite = styled.div`
    grid-column: 3 / -2 !important;
    margin-top: 3rem;

    @media (min-width: ${(props) => props.theme.md}) {
        grid-column: 5 / -4 !important;
    }

    @media (min-width: ${(props) => props.theme.lg}) {
        grid-column: 3 / -4 !important;
    }

    a {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover span {
            text-decoration-color: ${(props) => props.theme.colorPrimary};
            text-decoration: underline;
        }

        span {
            color: ${(props) => props.theme.colorPrimary};
            font-size: 0.888rem;
            font-family: 'SawtonBauhaus';
            font-weight: 500;
        }
    }
`
