import React from 'react'
import styled from 'styled-components'

import Markdown from '../../utils/markdown'
import { isType } from '../../utils/typecheck'

export default function TextImage({ type, text, image }) {
    switch (true) {
        case isType(type, 'Text-Bild'):
            return (
                <TextImageContainer className="text-image">
                    <div className="text">
                        <Markdown content={text} />
                    </div>
                    <figure className="image">
                        <img src={image} alt="" />
                    </figure>
                </TextImageContainer>
            )

        case isType(type, 'Bild-Text'):
            return (
                <ImageTextContainer className="text-image">
                    <figure className="image">
                        <img src={image} alt="" />
                    </figure>
                    <div className="text">
                        <Markdown content={text} />
                    </div>
                </ImageTextContainer>
            )

        default:
            return null
    }
}

const TextImageContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);

    .text {
        grid-column: 3 / -3;
        margin-bottom: 1rem;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 2 / -13;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / -13;
        }

        h3 {
            font-weight: 300;
            font-size: clamp(1.25rem, calc(1.0392rem + 0.9486vw), 1.777rem);
        }
    }

    .image {
        grid-column: 3 / -2;
        margin-bottom: 1rem;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 16 / -2;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 16 / -3;
        }
    }
`

const ImageTextContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);

    .image {
        grid-column: 2 / -3;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 2 / -13;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 3 / -13;
        }
    }

    .text {
        grid-row: 1 / 2;
        grid-column: 3 / -2;
        margin-bottom: 1rem;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 16 / -2;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 16 / -3;
        }

        h3 {
            font-weight: 300;
            font-size: clamp(1.25rem, calc(1.0392rem + 0.9486vw), 1.777rem);
        }
    }
`
