import React from 'react'
import ReactMarkdown from 'react-markdown'

// Support for markdown strikethrough, tables, tasklists and URLs directly.
import gfm from 'remark-gfm'

// Support for line-breaks.
import breaks from 'remark-breaks'

/**
 * Render markdown
 * @param p Render <p> or not
 */
export default function Markdown({ content, p = true }) {
    if (content) {
        if (content.includes('mailto:') || content.includes('tel:')) {
            content = content.replace('http://', '')
        }
    }
    if (p) {
        return (
            <ReactMarkdown remarkPlugins={[gfm, breaks]}>
                {content}
            </ReactMarkdown>
        )
    } else {
        return (
            <ReactMarkdown
                remarkPlugins={[gfm, breaks]}
                disallowedElements={['p']}
                unwrapDisallowed={true}
            >
                {content}
            </ReactMarkdown>
        )
    }
}
