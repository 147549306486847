import React from 'react'
import styled from 'styled-components'

import Markdown from '../../utils/markdown'
import { isType } from '../../utils/typecheck'
import WideArrowLink from '../simple/wideArrowLink'

export default function CTASection({ ctaElements }) {
    const content = ctaElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'Kolumne'):
                return (
                    <span key={index} className="kicker">
                        {formattedContent}
                    </span>
                )

            case isType(item.data.Type, 'H2'):
                return (
                    <h2 key={index} className="h1">
                        {formattedContent}
                    </h2>
                )

            case isType(item.data.Type, 'Link'):
                return (
                    <WideArrowLink
                        key={index}
                        onWhiteBackground={true}
                        text={item.data.Content}
                    />
                )
        }
    })

    return (
        <CTASectionContainer className="cta-section">
            {content}
        </CTASectionContainer>
    )
}

const CTASectionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    padding: 2.5rem 0;
    color: ${(props) => props.theme.colorPrimary};
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;

    @media (min-width: 1422px) {
        overflow-x: visible;
    }

    @media (min-width: ${(props) => props.theme.md}) {
        padding: 5.5rem 0 8rem 0;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100%;
        background-color: ${(props) => props.theme.colorGrayLight};
        z-index: -1;
        grid-column: 2 / 26;
    }

    h2 {
        margin-bottom: 0;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column-end: -4;
        }
    }
`
