import React from 'react'
import styled from 'styled-components'

import { isType } from '../../utils/typecheck'
import Markdown from '../../utils/markdown'
import Check from '../../images/check.inline.svg'
import WideArrowLink from '../simple/wideArrowLink'
import JobLink from '../simple/jobLink'

export default function JobDescription({ jobDescriptionElements }) {
    let twoColumnsCounter = -1
    let listItemCounter = -1
    const content = jobDescriptionElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'Kolumne'):
                return (
                    <span key={index} className="kicker">
                        {formattedContent}
                    </span>
                )

            case isType(item.data.Type, 'H2'):
                return (
                    <h2 key={index} className="h1">
                        {formattedContent}
                    </h2>
                )

            case isType(item.data.Type, 'Stellen'):
                return item.data.Slugs.map((slug, i) => (
                    <JobLink key={`${index}-${i}`} jobSlug={slug} />
                ))

            case isType(item.data.Type, 'H3'):
                if (
                    isType(
                        jobDescriptionElements[index + 1].data.Type,
                        'Fließtext'
                    )
                ) {
                    twoColumnsCounter++
                    return (
                        <div
                            key={index}
                            className={'two-columns-' + twoColumnsCounter}
                        >
                            <h3 className="h2">{formattedContent}</h3>
                            <Markdown
                                content={
                                    jobDescriptionElements[index + 1].data
                                        .Content
                                }
                            />
                        </div>
                    )
                } else {
                    return (
                        <React.Fragment key={index}>
                            <hr />
                            <h3 className="h2">{formattedContent}</h3>
                        </React.Fragment>
                    )
                }

            case isType(item.data.Type, 'Listenpunkt'):
                listItemCounter++
                return (
                    <div key={index} className={'li li-' + listItemCounter}>
                        <Check />
                        <Markdown content={item.data.Content} />
                    </div>
                )

            case isType(item.data.Type, 'Link'):
                return (
                    <WideArrowLink
                        key={index}
                        onWhiteBackground={true}
                        text={item.data.Content}
                    />
                )
        }
    })

    return (
        <JobDescriptionContainer className="job-description">
            {content}
        </JobDescriptionContainer>
    )
}

const JobDescriptionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    padding: 2.5rem 0;
    color: ${(props) => props.theme.colorPrimary};
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;

    @media (min-width: 1422px) {
        overflow-x: visible;
    }

    @media (min-width: ${(props) => props.theme.md}) {
        padding: 5.5rem 0 8rem 0;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        right: 0;
        height: 100%;
        background-color: ${(props) => props.theme.colorGrayLight};
        z-index: -1;
        grid-column: 1 / 25;
    }

    hr {
        grid-column: 3 / -4;
        opacity: 0.75;
        width: 100%;
        color: ${(props) => props.theme.colorPrimary};

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -5;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / -4;
        }
    }

    a.job + hr {
        display: none;
    }

    h2 {
        grid-column-end: -4;
    }

    .job {
        grid-column: 3 / -2;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -4;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / -4;
        }

        color: ${(props) => props.theme.colorPrimary};
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'SawtonBauhaus';
        padding: 0.5rem 0;
        border-bottom: 0.1rem solid ${(props) => props.theme.colorPrimary};

        &:first-of-type {
            margin-top: 1rem;
            border-top: 0.1rem solid ${(props) => props.theme.colorPrimary};

            @media (min-width: ${(props) => props.theme.md}) {
                margin-top: 3rem;
            }
        }

        .title {
            font-size: 1.222rem;
            margin-right: 1rem;
        }

        .link {
            display: flex;
            align-items: center;

            @media (min-width: ${(props) => props.theme.md}) {
                min-width: 10rem;
            }

            span {
                margin-right: 2rem;
                font-weight: 500;
                font-size: 0.888rem;
                display: none;

                @media (min-width: ${(props) => props.theme.md}) {
                    display: inline;
                }
            }

            .button {
                color: ${(props) => props.theme.colorPrimary};
            }
        }
    }

    .two-columns-0,
    .two-columns-1 {
        margin: 1.5rem 0;
        grid-column: 3 / -4;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -5;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / span 8;
        }
    }

    .two-columns-1 {
        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 15 / span 8;
        }
    }

    .li {
        margin-top: 1rem;
        grid-column: 3 / -4;

        h4 {
            padding-top: 0.5rem;
        }

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -5;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / span 8;

            &:nth-of-type(even) {
                grid-column: 15 / span 8;
            }
        }
    }

    .li.li-0,
    .li.li-3 {
        @media (min-width: ${(props) => props.theme.xl}) {
            grid-column: 6 / span 5;
        }
    }

    .li.li-1,
    .li.li-4 {
        @media (min-width: ${(props) => props.theme.xl}) {
            grid-column: 12 / span 5;
        }
    }

    .li.li-2,
    .li.li-5 {
        @media (min-width: ${(props) => props.theme.xl}) {
            grid-column: 18 / span 5;
        }
    }

    .link-container {
        grid-column: 3 / -4 !important;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -5 !important;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 3 / -4 !important;
        }
    }
`
